import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alertController: AlertController) { }
  alert(header,message,textButton) {
    this.alertController.create({
      header,
      message,
      buttons: [{text: textButton,handler: () => {this.alertController.dismiss().then( );}}],
    }).then((response) => {
      response.present().then();
    });
  }
  async alertConfirm(header, message,func) {
    const alert = this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {this.alertController.dismiss();}
        },
        {
          text: 'Aceptar',
          handler: () => {
              func();
          }
        }
      ]
    });
   return (await alert).present();

  }
}
