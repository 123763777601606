import { Injectable } from '@angular/core';
import {NavController} from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor(public navService: NavController) { }
  navToRoot(route) {
    return this.navService.navigateRoot(route);
  }
  navPush(route) {
    return this.navService.navigateForward(route);
  }
  navPop() {
    return this.navService.pop();
  }
  navBack() {
    return this.navService.back();
  }
  navBackDirection(route) {
    return this.navService.navigateBack(route);
  }
  navRootDirection(route) {
    return this.navService.navigateRoot(route);
  }
  navForward(route) {
    return this.navService.navigateForward(route);
  }
}
