import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {GLOBAL} from '../global/global.service';
import {Observable} from 'rxjs';
import {DataSheetEnterprise,DataSheetResume} from '@models';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DataSheetService {
  url='';
  token='';
  constructor(private http: HttpClient){
    this.url=GLOBAL.urlConcentrado;
    this.token=localStorage.getItem('token');
  }
  getDataSheet(fecha, tipo,token): Observable<DataSheetEnterprise>{
    // const params = {fecha,idEmpresa:tipo,type:'enterprise'};
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',Accept:'application/json', Authorization: `Bearer ${token}`};
    switch (tipo){
      default:
        this.url=environment.host+`hoja-datos/enterprise/${tipo}?fecha=${fecha}`;
    }

      return this.http.get<DataSheetEnterprise>(this.url, {headers});
  }
  getDataSheetResume(fecha,token): Observable<DataSheetResume>{
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',Accept:'application/json', Authorization: `Bearer ${token}`};
    return this.http.get<DataSheetResume>(environment.host + `hoja-datos/resume?fecha=${fecha}`, {headers});
  }
}
