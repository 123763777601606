import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard,AuthService } from '@services';

const routes: Routes = [
  {
    path:'',
    redirectTo:'app/hoja-datos',
    pathMatch:'full'
  },
  {
    path: 'app',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('../pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [AuthGuard,AuthService]
})
export class AppRoutingModule {}
