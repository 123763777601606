import { Injectable } from '@angular/core';
import {ModalController} from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public modalController: ModalController) { }
 async modal(component,classe='',func,data?) {
    const modal=await  this.modalController.create({
      component,
      cssClass: classe,
      componentProps: data,
      swipeToClose: true,
    });
    modal.onWillDismiss().then(
      (res)=>{if(res.data){
        func(res.data);
      }}
    );
    return await modal.present();
  }
  dismiss(data?) {
    return this.modalController.dismiss(data);
  }
}
