/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GLOBAL} from '@services';
import {Observable} from "rxjs";
import {InfoShip, MainResumeNaviera, ResumeNaviera} from "@models";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataResumeNavieraService {
  url='';
  token='';
  constructor(private http: HttpClient){
    this.url=GLOBAL.urlConcentrado;
    this.token=localStorage.getItem('token');
  }
  getDataSheetNaviera(fecha,token,idEmpresa): Observable< Array<ResumeNaviera>>{
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',Accept:'application/json', Authorization: `Bearer ${token}`};
    // eslint-disable-next-line max-len
    return this.http.get<Array<ResumeNaviera>>(environment.host + `hoja-datos/getResumeNavieras?fecha=${fecha}&idEmpresa=${idEmpresa}`, {headers});
  }
}
