/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GLOBAL} from "../global/global.service";
import {Observable} from "rxjs";
import {DataSheetResume} from "@models";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataGroupResumeService {

  url='';
  token='';
  constructor(private http: HttpClient){
    this.url=GLOBAL.urlConcentrado;
    this.token=localStorage.getItem('token');
  }
  getDataGroupResume(fecha,token): Observable<DataSheetResume>{
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',Accept:'application/json', Authorization: `Bearer ${token}`};
    return this.http.get<DataSheetResume>(environment.host + `hoja-datos/getResumenGroups?fecha=${fecha}`, {headers});
  }
}
