import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {GLOBAL} from '../global/global.service';
import {Observable} from 'rxjs';
import {DataCalendar} from '@models';
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  url='';
  token='';
  constructor(private http: HttpClient) {
    this.url=GLOBAL.urlConcentrado;
    this.token=localStorage.getItem('token');
  }
  getEventos(fecha,token): Observable<DataCalendar>{
    const params = {fecha};
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',Accept:'application/json', Authorization: `Bearer ${token}`};

    // @ts-ignore
    return this.http.get<DataCalendar>(this.url + `calendar?fecha=${fecha}`,  {headers});
  }
}
