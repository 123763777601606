import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
    load: any;
  constructor(public loadingController: LoadingController) { }
  //create and present loader
  async loader(message) {
    this.load= await this.loadingController.create({
      message
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    await this.load.present();
  }

  // Dismiss loader
 async dismissLoader() {
   await this.load.dismiss();
    // return this.loadingController.dismiss().then((response) => {
    // }).catch((err) => {
    //   console.log('Error occured : ', err);
    // });
  }
}
