import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private bSubj = new BehaviorSubject<Array<{ nombre: string }>>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  bSubj$ = this.bSubj.asObservable();

  setPermission(val: Array<any>): void {
    this.bSubj.next(val);
  }

  getPermission(){
    return localStorage.getItem('permisos');
  }
  getToken(){
    return localStorage.getItem('token');
  }
}
