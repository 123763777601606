import { Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isMobile = {
    android: () => navigator.userAgent.match(/Android/i),
    blackBerry: () => navigator.userAgent.match(/BlackBerry/i),
    iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
    opera: () => navigator.userAgent.match(/Opera Mini/i),
    windows: () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i),
    anyDevice() {
      return (this.android() || this.blackBerry() || this.iOS() || this.opera() || this.windows());
    }
  };
  constructor() {
    if(!this.isMobile.anyDevice()){
      location.href='https://www.concentrado.wildtours.com/';
    }
  }

}
