import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Login,ForgetPassword} from '@models';
import {Observable,of} from 'rxjs';
@Injectable({
  providedIn: 'root'
})

@Injectable()
export class AuthService{
  validate: false;
  constructor(private http: HttpClient){

  }
  check() {
    return (!(localStorage.getItem('token') === null || localStorage.getItem('token') === undefined));
  }

  saveToken(token,user,permisos){
    localStorage.setItem('token',token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('permisos', JSON.stringify(permisos));
  }

  logout(){
    localStorage.clear();
  }

  attempt(username, password){
    // const params = {username,password};
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' ,Accept:'application/json'};
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = new HttpParams()
      .set('username', username)
      .set('password', password);
    return this.http.post(environment.host + 'login',  params.toString(), {headers})
      .pipe(
        map((res: Login) => res)
      );
  }

  verifyEmail(email): Observable<ForgetPassword>{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' ,Accept:'application/json'};
    const params = new HttpParams()
      .set('email', email);
    return this.http.post(environment.host + 'reset-password',  params.toString(), {headers})
      .pipe(
        map((res: ForgetPassword) => res)
      );
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  resetUserAndPassword(new_password,repeat_password,token): Observable<Login>{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' ,Accept:'application/json'};
    const params = new HttpParams()
      .set('password', new_password)
      .set('confirmPassword', repeat_password)
      .set('verifyToken', token);
    return this.http.put(environment.host + 'user-password',  params.toString(), {headers})
      .pipe(
        map((res: Login) => res)
      );
  }

}
